// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/github-markdown-css/github-markdown.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".carousel-3d-slide {\n  background-color: transparent !important;\n}\n\n.carousel-3d-container {\n  margin: 0 !important;\n}\n\n.vue-square-handler {\n  background: #0082c3;\n  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;\n}\n\n.app__price-cartridge {\n  color: black;\n  background-color: #ffea28;\n  font-size: 12px;\n  font-family: \"Roboto Condensed\", Roboto, sans-serif;\n  line-height: 16px;\n  padding: 0.2rem 0.3rem;\n  font-weight: bold;\n}\n.app__price-cartridge_discounted {\n  color: white;\n  background-color: #e20c18;\n}\n\n.draggable-handle,\n.drag-handle {\n  cursor: grab;\n}\n\n.draggable-handle:active,\n.drag-handle:active {\n  cursor: grabbing;\n}\n\n.draggable-ghost {\n  opacity: 0;\n}\n\n.text-condensed {\n  font-family: \"Roboto Condensed\", Roboto, sans-serif !important;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
