var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "fade-transition" } },
    [
      _c(
        "v-overlay",
        { attrs: { "z-index": "20", absolute: _vm.absolute, opacity: "0.6" } },
        [
          _c("Spinner", {
            attrs: {
              "animation-duration": 1500,
              size: 50,
              color: _vm.spinnerColor,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }